<template>
  <v-container
    fluid
    class="primary"
    style="position: fixed; min-height: 100vh; overflow-y: scroll"
  >
    <v-row style="height: 100vh" class="ml-6">
      <v-col class="ml-6 d-flex flex-column justify-start">
        <v-card elevation="1" class="mb-3">
          <v-card-title>Units</v-card-title>
          <v-dialog v-model="dialog" max-width="800px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="role === 'Admin' || role === 'Head'"
                color="primary"
                outlined
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                style="position: absolute; top: 10px; right: 10px;"
              >
                New Unit
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary white--text">
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedUnit.unitNumber"
                          label="Unit Number"
                          :rules="[v => !!v || 'Unit number is required']"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          :items="unitStatuses"
                          v-model="editedUnit.unitStatus"
                          label="Status"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          :items="unitLocations"
                          v-model="editedUnit.location"
                          label="Location"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedUnit.type"
                          label="Type"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedUnit.floor"
                          label="Floor"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedUnit.zone"
                          label="Zone"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="red darken-1" text @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-card-subtitle>
            <v-row>
              <v-col cols="12" sm="3"
                ><strong>Total Units:</strong> {{ compTotalUnits }}
              </v-col>
              <v-col cols="12" sm="3"
                ><strong>Available Units:</strong> {{ compAvailableUnits }}
              </v-col>
              <v-col cols="12" sm="3"
                ><strong>Unavailable Units:</strong> {{ compUnavailableUnits }}
              </v-col>
              <v-col cols="12" sm="3"
                ><strong>Booked Units:</strong> {{ compBookedUnits }}
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-title>
            <v-row>
              <v-col cols="12" sm="3">
                <v-select
                  v-model="searchLocation"
                  :items="searchLocations"
                  label="Location"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-select
                  v-model="searchType"
                  :items="compSearchTypes"
                  label="Type"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3">
                <v-switch
                  v-model="showDisabled"
                  label="Show Disabled?"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="compUnits"
            item-key="_id"
            :expanded.sync="expanded"
            show-expand
            :footer-props="{ 'items-per-page-options': [20, 50, 100, -1] }"
          >
            <!-- :search="search" -->
            <template v-slot:item.enabled="{ item }">
              <v-icon v-if="item.enabled" small>mdi-check-bold</v-icon>
            </template>
            <template v-slot:item.customerDetails="{ item }">
              {{ item.customerDetails.firstname }}
              {{ item.customerDetails.lastname }}
            </template>
            <template v-slot:item._id="{ item }">
              <v-btn icon @click="emitShowEditUnitModal(item)">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.createdOn="{ item }">
              {{ friendlyDate(item.createdOn) }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-flex xs12>
                  <UnitDetailsCard :unit="item" class="my-3" />
                </v-flex>
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-card elevation="1" class="mb-12">
          <v-card-title>Unit Pricing</v-card-title>
          <v-dialog v-model="dialogPrice" max-width="800px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                outlined
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                style="position: absolute; top: 10px; right: 10px;"
              >
                New Price
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary white--text">
                <span class="headline">{{ formPriceTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          :items="unitLocations"
                          v-model="editedPrice.location"
                          label="Location"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedPrice.type"
                          label="Type"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedPrice.pricePerWeek"
                          label="Price per week"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedPrice.pricePerMonth"
                          label="Price per month"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedPrice.pricePerHalfYear"
                          label="Price per half year"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedPrice.pricePerYear"
                          label="Price per year"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedPrice.priceSubscription"
                          label="Subscription Price"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          :items="compParentTypes"
                          v-model="editedPrice.parent"
                          label="Parent"
                          clearable
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedPrice.width"
                          :rules="[rules.numeric]"
                          label="Unit Width"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedPrice.length"
                          :rules="[rules.numeric]"
                          label="Unit Length"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedPrice.height"
                          :rules="[rules.numeric]"
                          label="Unit Height"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedPrice.weightLimit"
                          :rules="[rules.numeric]"
                          label="Weight Limit"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedPrice.doorWidth"
                          :rules="[rules.numeric]"
                          label="Door Width"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedPrice.doorHeight"
                          :rules="[rules.numeric]"
                          label="Door Height"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <label>Description</label>
                        <hr
                          style="height: 0px; border: none; border-bottom: 1px solid #949494; padding: 3px 0px; margin-bottom: 10px;"
                        />
                        <tiptap
                          :value="editedPrice.description"
                          @updateContent="updatePriceContent"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="dialogPrice = false">
                  Cancel
                </v-btn>
                <v-btn color="red darken-1" text @click="savePrice">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-data-table
            :headers="headersPricing"
            :items="compPricingData"
            item-key="_id"
            :sort-by="['createdOn']"
            :sort-desc="[true]"
            :expanded.sync="expandedPrice"
            show-expand
            :search="search"
          >
            <template v-slot:item._id="{ item }">
              <v-btn icon @click="emitShowEditPricingModal(item)">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="my-3">
                    <label>Price per week</label>
                    <strong> {{ item.pricePerWeek }} </strong> <br />
                    <label>Price per month</label>
                    <strong> {{ item.pricePerMonth }} </strong> <br />
                    <label>Price per half year</label>
                    <strong> {{ item.pricePerHalfYear }} </strong> <br />
                    <label>Price per year</label>
                    <strong> {{ item.pricePerYear }} </strong> <br />
                    <label>Subscription price</label>
                    <strong> {{ item.priceSubscription }} </strong> <br />
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="my-3">
                    <label>Width</label>
                    <strong> {{ item.width }} </strong> <br />
                    <label>Length</label>
                    <strong> {{ item.length }} </strong> <br />
                    <label>Height</label>
                    <strong> {{ item.height }} </strong> <br />
                    <label>Weight Limit</label>
                    <strong> {{ item.weightLimit }} </strong> <br />
                    <label>Door Width</label>
                    <strong> {{ item.doorWidth }} </strong> <br />
                    <label>Door Height</label>
                    <strong> {{ item.doorHeight }} </strong> <br />
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import helpers from "@/plugins/helpers";
const axios = require("axios");
axios.defaults.withCredentials = true;
import moment from "moment";
import UnitDetailsCard from "@/components/unitDetailsCard.vue";
import Tiptap from "@/components/Tiptap.vue";
// import FloorPlanMaster from "@/components/floorPlanMaster.vue";
// var fuzzy = require("fuzzy");
export default {
  name: "Units",
  components: {
    UnitDetailsCard,
    Tiptap
    // FloorPlanMaster
  },
  data: () => ({
    showDisabled: false,
    searchLocation: "",
    searchLocations: ["ALL", "RAMA 9", "LKB", "LCB"],
    searchType: "ALL",
    unitLocations: ["RAMA 9", "LKB", "LCB"],
    unitStatuses: ["Available", "Unavailable"],
    unitID: null,
    expanded: [],
    expandedPrice: [],
    search: "",
    headers: [
      { text: "Location", value: "location" },
      { text: "Unit No.", value: "unitNumber" },
      { text: "Enabled?", value: "enabled" },
      { text: "Floor", value: "floor" },
      { text: "Zone", value: "zone" },
      { text: "Type", value: "type" },
      { text: "Status", value: "unitStatus" },
      { text: "", value: "_id", sortable: false },
      { text: "", value: "data-table-expand" }
    ],
    headersPricing: [
      { text: "Location", value: "location" },
      { text: "Type", value: "type" },
      { text: "Parent", value: "parent" },
      { text: "", value: "_id", sortable: false },
      { text: "", value: "data-table-expand" }
    ],
    valid: true,
    rules: {
      required: value => !!value || "Required."
    },
    dateRange: "",
    dateRanges: [
      "Last 7 Days",
      "Last 30 Days",
      "Last 6 Months",
      "Last 12 Months"
    ],
    url: "",
    author: "",
    editDocumentDialog: false,
    editedDocument: {
      title: "",
      category: "",
      URL: ""
    },
    dialog: false,
    dialogPrice: false,
    editedIndex: -1,
    editedPriceIndex: -1,
    editedUnit: {
      username: "",
      firstname: "",
      lastname: "",
      role: "",
      phone: "",
      password: ""
    },
    editedPrice: {}
    // pricingData: []
  }),
  computed: {
    pricingData() {
      return this.$store.state.pricing;
    },
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    },
    compUnits() {
      let data = this.$store.state.units;

      // remove disabled?
      if (!this.showDisabled) {
        data = data.filter(u => u.enabled === true);
      }

      if (this.unitID) {
        const filteredUnits = data.filter(u => u._id === this.unitID);
        data = filteredUnits;
      }

      // filter on location
      if (this.searchLocation !== "ALL") {
        let results = data.filter(d => d.location === this.searchLocation);
        data = results;
      }

      // filter on type
      if (this.searchType !== "ALL") {
        let results = data.filter(d => d.type === this.searchType);
        data = results;
      }

      // sort by createdOn desc
      data.sort(this.sortDates);

      return data;
    },
    compPricingData() {
      let data = this.pricingData;

      // filter on location
      if (this.searchLocation !== "ALL") {
        let results = data.filter(d => d.location === this.searchLocation);
        data = results;
      }

      // filter on type
      if (this.searchType !== "ALL") {
        let results = data.filter(d => d.type === this.searchType);
        data = results;
      }

      return data;
    },
    compUnitTypes() {
      return [
        "XS",
        "XS A/C",
        "S (Column)",
        "S",
        "S A/C",
        "S A/C (Column)",
        "M (Column)",
        "M",
        "M A/C",
        "M A/C (Column)",
        "L (Column)",
        "L",
        "L A/C",
        "L A/C (Column)",
        "XL (Column)",
        "XL",
        "XL A/C",
        "XL A/C (Column)",
        "XL (1st floor)",
        "XXL (Column)",
        "XXL",
        "XXL A/C",
        "XXL A/C (Column)",
        "XXL (1st floor)",
        "Garage",
        "280",
        "280 A/C",
        "400",
        "400 A/C",
        "0.45",
        "12",
        "18",
        "60",
        "95",
        "120"
      ];
      // let data = this.$store.state.units;
      // let uniqueTypes = [...new Set(data.map((item) => item.type))];
      // uniqueTypes.sort();
      // return uniqueTypes
    },
    compParentTypes() {
      let data = this.pricingData;
      let uniqueTypes = [...new Set(data.map(item => item.type))];
      uniqueTypes.sort();
      return uniqueTypes;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Unit" : "Edit Unit";
    },
    formPriceTitle() {
      return this.editedPriceIndex === -1 ? "New Price" : "Edit Price";
    },
    compSearchTypes() {
      let data = this.$store.state.units;
      let uniqueTypes = [...new Set(data.map(item => item.type))];
      uniqueTypes.sort();
      uniqueTypes.unshift("ALL");
      return uniqueTypes;
    },
    compTotalUnits() {
      let data = this.compUnits;
      return data.length;
    },
    compAvailableUnits() {
      let data = this.compUnits;
      data = data.filter(d => d.unitStatus === "Available");
      return data.length;
    },
    compUnavailableUnits() {
      let data = this.compUnits;
      data = data.filter(d => d.unitStatus === "Unavailable");
      return data.length;
    },
    compBookedUnits() {
      let data = this.compUnits;
      data = data.filter(d => d.unitStatus === "Booked");
      return data.length;
    }
  },
  methods: {
    sortDates(a, b) {
      // Convert dates to milliseconds and compare
      return moment(a.createdOn).isBefore(moment(b.createdOn));
    },
    updatePriceContent(content) {
      this.editedPrice.description = content;
    },
    emitShowEditUnitModal(unit) {
      this.$emit("showEditUnitModal", unit);
    },
    emitShowEditPricingModal(pricing) {
      console.log("clicked edit icon");
      this.$emit("showEditPricingModal", pricing);
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedUnit = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      let vm = this;
      let unit = this.editedUnit;
      let index = this.editedIndex;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          axios
            .put(vm.$root.urls.api + "/v3/units", {
              unit
            })
            .then(function(results) {
              Object.assign(vm.units[index], unit);
              // toast
              vm.$toasted.show("Unit updated").goAway(2500);
            });
        } else {
          // save new user via API
          axios
            .post(vm.$root.urls.api + "/v3/units", {
              unit,
              howMany: 1
            })
            .then(function(results) {
              // vm.units.push(unit);
              let newUnit = results.data;
              vm.$store.commit("addUnit", newUnit);
              // toast
              vm.$toasted.show("Unit added").goAway(2500);
            })
            .catch(function(error) {
              console.log(error);
              vm.$toasted
                .show(
                  "Unable to add unit. Either the unit number already exists, or you do not have permission to add units.",
                  {
                    type: "error",
                    icon: "mdi-alert-circle"
                  }
                )
                .goAway(2500);
            });
        }
        this.close();
      }
    },
    savePrice() {
      let vm = this;
      let price = this.editedPrice;
      let index = this.editedPriceIndex;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      if (this.$refs.form.validate()) {
        if (this.editedPriceIndex > -1) {
          axios
            .put(vm.$root.urls.api + "/v3/prices", {
              price
            })
            .then(function(results) {
              // update pricing data in state
              vm.$store.commit("updatePricing", price);
              // toast
              vm.$toasted.show("Price updated").goAway(2500);
            });
        } else {
          // save new user via API
          axios
            .post(vm.$root.urls.api + "/v3/prices", {
              price
            })
            .then(function(results) {
              // add price to state
              vm.$store.commit("addPricing", results.data);
              // toast
              vm.$toasted.show("Price added").goAway(2500);
            })
            .catch(function(error) {
              console.log(error);
              vm.$toasted
                .show("You do not have permission to add pricing data", {
                  type: "error",
                  icon: "mdi-alert-circle"
                })
                .goAway(2500);
            });
        }
        this.dialogPrice = false;
      }
    },
    friendlyDate(d) {
      return helpers.formatFriendlyDate(d);
    }
  },
  mounted: function() {
    // listen for unit id parameter
    if (this.$route.params.id) {
      this.searchLocation = "ALL";
      this.unitID = this.$route.params.id;
      this.expanded.push(this.compUnits[0]);
    }
  }
};
</script>
