<template>
  <v-card elevation="1" class="pa-2">
    <p v-if="unit.confirmation">
      Booking:
      <router-link v-bind:to="'/Bookings/' + unit.confirmation">{{
        unit.confirmation
      }}</router-link>
    </p>

    <table>
      <tr>
        <td><label for="">Location</label></td>
        <td>{{ unit.location }}</td>
        <td><label for="">Size</label></td>
        <td>{{ unit.type }}</td>
      </tr>

      <tr>
        <td><label for="">Floor</label></td>
        <td>{{ unit.floor }}</td>
        <td><label for="">Zone</label></td>
        <td>{{ unit.zone }}</td>
      </tr>

      <!-- <tr>
      <td><label for="">Width</label></td>
      <td>{{ unit.width }}</td>
      <td></td>
      <td></td>
    </tr> 

    <tr>
      <td><label for="">Length</label></td>
      <td>{{ unit.length }}</td>
      <td></td>
      <td></td>
    </tr> 

    <tr>
      <td><label for="">Height</label></td>
      <td>{{ unit.height }}</td>
      <td></td>
      <td></td>
    </tr>  -->
    </table>
  </v-card>
</template>

<script>
export default {
  name: "unit-details-card",
  props: {
    unit: Object
  },
  methods: {}
};
</script>

<style scoped>
table {
  width: 100%;
  /*		margin-bottom: 20px;*/
}

table label {
  font-weight: bold;
}
table tr td:nth-child(1) {
  width: 20%;
}
table tr td:nth-child(2) {
  width: 30%;
}
table tr td:nth-child(3) {
  width: 20%;
}
table tr td:nth-child(4) {
  width: 30%;
}
</style>
